@charset "UTF-8";
/* .el-tabs__item {
  width: 60px;
  background: linear-gradient(19deg, transparent 15px, #58a 0);
}  */
.el-tabs__item[data-v-10c7f5c0] {
  width: 50px;
  margin: 0 auto !important;
  height: 60px;
  line-height: 20px;
  writing-mode: vertical-lr; /*从左向右 从右向左是 writing-mode: vertical-rl;*/
  writing-mode: tb-lr; /*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*/
  border: 0px !important;
}
.el-tabs__item.is-active[data-v-10c7f5c0] {
  color: white !important;
}
.is-active[data-v-10c7f5c0] {
  background: linear-gradient(24deg, transparent 20px, #67caf9 0);
  background-color: #f5f7fa !important;
  color: white !important;
}